.sc-fzqBkg .sc-fzqPZZ .eDloXy{
  color: #ffffff !important;
}
.sc-fzqBkg {
  color: #ffffff !important;
}
.sc-fzqPZZ {
  color: #ffffff !important;
}
.eDloXy{
  color: #ffffff !important;
}